import './App.css';
//import LoginView from './DotGoal/LoginView';
import HomeView from './Structure/HomeView';
import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from './Managers/UserContext';
import LoadingIndicator from './Structure/LoadingIndicator';

// TODO:
//  - Add support for 'fake' user (?FakeUser=123 to URL allows using a fake user with that ID, handle in APIs via query strings, limit to 'debug' builds?)
//      -- This might be best done by updating the APIs to include the UserId in the path (/User/[123]/Goals), and then just granting anyone access to those regardless of auth
//

function DotGoalApp() {
  const { userState } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("DotGoalApp recalculating...");
    console.log(userState);

    if (userState.isLoading || !userState.isLoggedIn)
      return;

    if (userState.user === null)
      navigate("/createUser"); // User is logged in but doesn't have a User Object so redirect to create User
    else if (userState.goals === null || userState.goals.length === 0) {
      navigate("/tracking"); // We navigate here 'first' so that we return there when goal is created
      navigate("/createGoal"); // User is loaded but no Goals, so redirect to create Goals
    }
    else {
      navigate("/tracking"); // User and Goals have been loaded and exist so we're ready
    }
    // eslint-disable-next-line
  }, [userState.isLoading]);

  // While the effect stuff above is executing show loading indicator so we don't flash the HomeView
  if (userState.isLoading || userState.isLoggedIn)
    return <LoadingIndicator />;

  return <HomeView />;
}

export default DotGoalApp;
