export async function GetActiveUser() {
  try {
    const response = await fetch("/.auth/me");

    if (response.ok) {
      const responseJson = await (response.json());
      console.log("below is clientPrincipal responseJson");
      console.log(responseJson);
      const { clientPrincipal } = responseJson;
      return clientPrincipal;
    }
  }
  catch (error) {
    // handle network errors
    console.log('Network error: ' + error);
    throw error;
  }

  return null;
}