import './EditGoalView.css'
import { useState, useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from '../Managers/UserContext';
import LoadingIndicator from '../Structure/LoadingIndicator';

const titleErrorMsg = "Goal Title must be at least 1 character long";
const goalDataIconErrorMsg = "Goal Icon must be at least 1 character long";

// TODO: Bug in here because navigating to -1 will behave weirdly if directly navigating to this page
//      - Maybe we need to explicitly check if the previous page was /week or /tracking and navigate there
//        otherwise navigate to /
export default function EditGoalView() {
  const [goal, setGoal] = useState(null);

  const { userState, addGoal, updateGoal, deleteGoal } = useContext(UserContext);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (userState.isLoading)
      return;

    if (!userState.isLoggedIn) {
      console.log("Not logged in EditGoalView is redirecting to /");
      navigate("/");
      return;
    }
    else if (userState.user === null) {
      console.log("No User EditGoalView is redirecting to /");
      navigate("/");
      return;
    }

    let tempGoal = goal;
    // If we already have the goal in state, skip this lookup
    // Otherwise we'll override any in progress changes
    if (!tempGoal) {
      const goalId = searchParams.get('id');
      if (!goalId) {
        // If no goalId is provided, assume we are creating a new one
        tempGoal = {
          title: "",
          goalDataIcons: new Map([
            ["0", "🟢"],
            ["1", "🔴"]])
        };
      }
      else {
        // TODO: Fix error here if navigating directly to this page and user has no goals
        tempGoal = userState.goals.find(goal => goal.id === goalId);
        if (!tempGoal) {
          // TODO: Should this use the ErrorContext and RedirectToError or should the context capture this error?
          throw new Error("No goal found matching id from querystring: " + goalId);
        }
      }

      setGoal(tempGoal);
    }
    // eslint-disable-next-line
  }, [userState.isLoading]);

  // Until we have loaded the user and goal we need to wait
  if (userState.isLoading || goal === null)
    return <LoadingIndicator />;

  async function handleSubmit(e) {
    e.preventDefault();
    let anyErrors = false;

    if (!isTitleValid()) {
      anyErrors = true;
    }

    for (const key of goal.goalDataIcons.keys()) {
      if (!isGoalDataIconValid(key)) {
        anyErrors = true;
      }
    }

    if (!anyErrors) {
      if (goal.id)
        await updateGoal(goal.id, goal.title, goal.goalDataIcons);
      else
        await addGoal(goal.title, goal.goalDataIcons);
      navigate(-1);
    }
  }

  function handleTitleChange(e) {
    setGoal({ ...goal, title: e.target.value })
  }

  function handleGoalDataIconsChange(e, iconKey) {
    let newIconSet = new Map([...goal.goalDataIcons]);
    newIconSet.set(iconKey, e.target.value);
    setGoal({ ...goal, goalDataIcons: newIconSet });
  }

  function handleAddGoalDataIcon() {
    let newIconSet = new Map([...goal.goalDataIcons]);
    let largestNum = 0;
    for (const key of Array.from(newIconSet.keys())) {
      let keyInt = parseInt(key);
      if (keyInt && keyInt > largestNum)
        largestNum = keyInt;
    }
    newIconSet.set((largestNum + 1) + "", "");
    setGoal({ ...goal, goalDataIcons: newIconSet });
  }

  function handleDeleteGoalDataIcon(e, key) {
    e.stopPropagation();
    let newIconSet = new Map([...goal.goalDataIcons]);
    newIconSet.delete(key);
    setGoal({ ...goal, goalDataIcons: newIconSet });
  }

  async function handleDelete() {
    await deleteGoal(goal.id);
    navigate(-1);
  }

  function isTitleValid() {
    return goal.title.length >= 1;
  }

  function isGoalDataIconValid(iconKey) {
    return goal.goalDataIcons.get(iconKey)?.length >= 1;
  }

  function areGoalDataIconsValid() {
    return Array.from(goal.goalDataIcons.keys()).every(key => isGoalDataIconValid(key));
  }

  let goalIconEditing = Array.from(goal.goalDataIcons.keys()).map((key) => (
    <div key={`outer-div-${key}`}>
      <label key={`div-${key}`} >
        <input key={`icons-${key}`}
          name={`icons-${key}`}
          type="text"
          maxLength="2"
          onChange={(e) => handleGoalDataIconsChange(e, key)}
          value={goal.goalDataIcons.get(key)}
          className={isGoalDataIconValid(key) ? "GoalDataIconEdit" : "GoalDataIconEditError"}
          aria-describedby={`icons-${key}-error`} />
        <button key={`delete-${key}`} type="button" onClick={(e) => handleDeleteGoalDataIcon(e, key)}>delete</button>
      </label>
      <br />
    </div>
  ));

  // TODO: Below I have the "Select Goal Icons" text and AddIcon button in it's own label because when it was in the same Label as each of the goalIconEditing labels it appears as though clicking delete on one of those propogated the event up and also ended up executing the event for Add Icon. For now separated to avoid the issue because I couldn't fix it via just adding e.stopPropogation(), so it must be something deeper
  return (
    <>
      {goal.id ? "Edit Goal Page!" : "Create Goal Page!"}
      <hr />
      {goal.id ?
        <i>"Here you can Edit this Goal's Title (up to 50 characters) or the icons used for tracking (up to 10)."</i> :
        <>
          <i>Enter a Goal you would like to track (up to 50 characters), examples could be "Read everyday", "Go to the Gym", or "Do 100 Situps".</i>
          <i>You can also set the icons used for tracking (up to 10, these can be edited later).</i>
          <i>Later there will be the ability to set a goal frequency (x times per week, etc.), but for now it's just tracking daily.</i>
        </>}
      <hr />
      <form onSubmit={handleSubmit}>
        <label id="edit-title-label">
          Enter Goal Title: { }
          <input key="title"
            name="title"
            type="text"
            maxLength="50"
            onChange={handleTitleChange}
            value={goal.title}
            aria-describedby="title-error" />
          {!isTitleValid() && <div id="title-error" className="formErrorText" role="alert">{titleErrorMsg}</div>}
        </label>
        <br />
        <br />
        <label id="edit-icons-label-top">
          Select Goal Icons: {goalIconEditing.length < 10 ? <button type="button" key="addIcon" onClick={handleAddGoalDataIcon}>Add icon</button> : ""}
        </label>
        <br />
        <label id="edit-icons-label">
          {goalIconEditing}
        </label>
        <br />
        {!areGoalDataIconsValid() && <span id="icons-error" className="formErrorText" role="alert">{goalDataIconErrorMsg}</span>}
        <hr />
        <button type="submit">{goal.id ? "Update" : "Create"}</button>
        <button type="button" onClick={() => navigate(-1)} >Cancel</button>
      </form>
      {goal.id ?
        <>
          <br />
          <button type="button" onClick={handleDelete} >⚠️DELETE GOAL⚠️</button>
        </> :
        <></>}
    </>
  );
}