import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// TODO: If there are any problems with this, check out the new support here: https://reactrouter.com/en/main/components/scroll-restoration
export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.style.transform = 1;
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}