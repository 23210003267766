import { createContext, useEffect, useReducer, useContext } from 'react';

import userManager from './UserManager';
import { ErrorContext } from '../Structure/ErrorBoundary';
import { ResultType, GetUser, GetGoals, CreateGoal, CreateUser, PatchGoal, DeleteGoal } from '../DotGoal/Helpers/DataProvider.js';

export const UserContext = createContext(null);

// TODO: Rather than just having everyting in action.PAYLOAD we should have more clear fields such as action.goalIdToDelete ?
const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING_USER':
      return { ...state, isLoading: true };
    case 'FINISHED_LOADING':
      return { ...state, isLoading: false };
    case 'LOADED_LOGGEDIN':
      return { ...state, isLoggedIn: action.payload };
    case 'LOADED_USER':
      return { ...state, user: action.payload };
    case 'ADDED_USER':
      return { ...state, user: action.payload };
    case 'LOADED_GOALS':
      return { ...state, goals: action.payload };
    case 'ADDED_GOAL':
      return { ...state, goals: state.goals ? [...state.goals, action.payload] : [action.payload] };
    case 'UPDATED_GOAL':
    case 'SET_GOAL_DAY_VALUE':
      return { ...state, goals: state.goals.map((goal) => goal.id === action.payload.id ? action.payload : goal) };
    case 'DELETE_GOAL':
      return { ...state, goals: state.goals.filter((goal) => goal.id !== action.payload) };
    default:
      throw new Error();
  }
};

export function UserProvider({ children }) {
  const initialState = {
    isLoading: true,
    isLoggedIn: false,
    user: null,
    goals: null
  };
  const [userState, dispatch] = useReducer(reducer, initialState);
  const { redirectToError } = useContext(ErrorContext);

  function formatAPIRequestError(operationDetails, result) {
    // toString needed here on the resultType because it's a Symbol
    return new Error(`${operationDetails} - Unhandled Result: ${result?.resultType?.toString()} - ${result?.errorDescription}`);
  }

  useEffect(() => {
    async function getUser() {
      dispatch({ type: 'LOADING_USER' });

      try {
        let loggedInResult = await userManager.IsLoggedIn();
        dispatch({ type: 'LOADED_LOGGEDIN', payload: loggedInResult });

        if (loggedInResult) {
          var getUserResult = await GetUser();
          if (getUserResult && getUserResult.resultType === ResultType.SUCCESS) {
            dispatch({ type: 'LOADED_USER', payload: getUserResult.result });

            var getGoalsResult = await GetGoals();
            if (getGoalsResult && getGoalsResult.resultType === ResultType.SUCCESS) {
              dispatch({ type: 'LOADED_GOALS', payload: getGoalsResult.result });
              dispatch({ type: 'FINISHED_LOADING' }); // Nothing else to load now
            }
            else if (getGoalsResult && getGoalsResult.resultType === ResultType.NOT_FOUND) {
              // User object existed but no Goals, so we've finished loading
              dispatch({ type: 'FINISHED_LOADING' });
            }
            else {
              throw formatAPIRequestError("getGoals", getGoalsResult);
            }
          }
          else if (getUserResult && getUserResult.resultType === ResultType.NOT_FOUND) {
            // User is logged in but User object doesn't exist, so we've finished loading
            dispatch({ type: 'FINISHED_LOADING' });
          }
          else {
            throw formatAPIRequestError("getUser", getUserResult);
          }
        }
        else {
          // User isn't logged in, so we've finished loading
          dispatch({ type: 'FINISHED_LOADING' });
        }
      } catch (error) {
        redirectToError(error);
        // TODO: If I set finished loading then simply clicking go home puts the user into the next step
        //        e.g. Create User if GetUser failed, Create Goal if GetGoal fialed
        //        Should I introduce a new ERRORED state, but then what would I do with it?
        //        What would the home screen or otherscreens show until a refresh?
        //        For other flows it's fine it's just this initial GetUser and GetGoals that locks things
        //dispatch({ type: 'FINISHED_LOADING' });
      }
    }
    getUser();
    // eslint-disable-next-line
  }, []); // TODO: What is the effect of including [] here vs nothing vs all the dependent functions

  async function addGoal(goalTitle, goalDataIcons) {
    try {
      const createGoalResult = await CreateGoal(goalTitle, goalDataIcons);
      if (createGoalResult && createGoalResult.resultType === ResultType.SUCCESS) {
        dispatch({ type: 'ADDED_GOAL', payload: createGoalResult.result });
      }
      else {
        throw formatAPIRequestError("addGoal", createGoalResult);
      }
    }
    catch (error) {
      redirectToError(error);
    }
  }

  async function addUser(displayName) {
    try {
      const createUserResult = await CreateUser(displayName);
      if (createUserResult && createUserResult.resultType === ResultType.SUCCESS) {
        dispatch({ type: 'ADDED_USER', payload: createUserResult.result });
      }
      else {
        throw formatAPIRequestError("addUser", createUserResult);
      }
    }
    catch (error) {
      redirectToError(error);
    }
  }

  // TODO: This should maybe be modified to do optimistic UI updating in which the model is updated before the API call
  //      - NOTE: We would need to handle failure cases either by undoing the change or just bailing and reloading everything
  //          but that might be difficult if multiple updates are sent in rapid succession
  // TODO: Should we introduce a delayed update here where we batch Goal updates and send a single patch after say 3 seconds?
  //      - This would help in the scenario where we are tapping quickly through the icons either on a single goal-day or
  //        across goals and/or days
  async function setGoalDayValue(goalId, dateString, iconKey) {
    try {
      let goalToModify = userState.goals.find(goal => goal.id === goalId);
      if (!goalToModify) {
        console.log("Unable to find goal to modify with id: " + goalId);
        return;
      }

      let updatedGoalLogs = new Map(goalToModify.goalLogs);
      updatedGoalLogs.set(dateString, iconKey);

      const patchResult = await PatchGoal(goalId, { goalLogs: updatedGoalLogs })
      if (patchResult && patchResult.resultType === ResultType.SUCCESS) {
        dispatch({ type: 'SET_GOAL_DAY_VALUE', payload: patchResult.result });
      }
      else {
        throw formatAPIRequestError("setGoalDayValue", patchResult);
      }
    }
    catch (error) {
      redirectToError(error);
    }
  }

  async function updateGoal(goalId, title, goalDataIcons) {
    try {
      let goalToModify = userState.goals.find(goal => goal.id === goalId);
      if (!goalToModify) {
        console.log("Unable to find goal to modify with id: " + goalId);
        return;
      }

      const patchResult = await PatchGoal(goalId, { title: title, goalDataIcons: goalDataIcons });
      if (patchResult && patchResult.resultType === ResultType.SUCCESS) {
        dispatch({ type: 'UPDATED_GOAL', payload: patchResult.result });
      }
      else {
        throw formatAPIRequestError("updateGoal", patchResult);
      }
    }
    catch (error) {
      redirectToError(error);
    }
  }

  async function deleteGoal(goalId) {
    try {
      const deleteResult = await DeleteGoal(goalId);
      if (deleteResult && deleteResult.resultType === ResultType.SUCCESS) {
        dispatch({ type: 'DELETE_GOAL', payload: goalId });
      }
      else {
        throw formatAPIRequestError("deleteGoal", deleteResult);
      }
    }
    catch (error) {
      redirectToError(error);
    }
  }

  return (
    <UserContext.Provider value={{ userState, addGoal, addUser, setGoalDayValue, updateGoal, deleteGoal }}>
      {children}
    </UserContext.Provider>
  );
};