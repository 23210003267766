import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { UserContext } from '../Managers/UserContext';

function SiteHeader() {
  const { userState } = useContext(UserContext);

  return (
    <Navbar collapseOnSelect bg="light" expand="sm">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand as={Link} to="/">DotGoal App</Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {userState.isLoggedIn ?
              <>
                <Nav.Link as={NavLink} to="/tracking">Day Tracking</Nav.Link>
                <Nav.Link as={NavLink} to="/week">Week View</Nav.Link>
                <Nav.Link as={NavLink} to="/rollingweek">Rolling Week View</Nav.Link>
              </> :
              <></>}
            <Nav.Link as={NavLink} to="/About">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Text>{userState.isLoading ? "loading..." : userState.user?.displayName}{userState.isLoggedIn ? <> - <a href="/.auth/logout">Logout</a></> : (<a href="/.auth/login/aad">Login</a>)}</Navbar.Text>
      </Container>
    </Navbar>
  );
}

export default SiteHeader;
