import './CalendarView.css';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import DotButton from './DotButton.js';
import LoadingIndicator from '../Structure/LoadingIndicator';
import { useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { GetDateStringDisplayObject, GetMonthsAndCounts } from "./Helpers/DateProvider.js";
import { UserContext } from '../Managers/UserContext';

export default function CalendarView({ dateList, disableGreaterThanDateString, highlightDateString, onDateBackClicked, onDateForwardClicked }) {
  const { userState, setGoalDayValue } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.isLoading)
      return;

    if (!userState.isLoggedIn) {
      console.log("Not logged in CalendarView is redirecting to /");
      navigate("/");
      return;
    }
    else if (userState.user === null) {
      console.log("No User CalendarView is redirecting to /");
      navigate("/");
      return;
    }
    else if (userState.goals === null) {
      console.log("No goals CalendarView is redirecting to /");
      navigate("/");
      return;
    }
    // eslint-disable-next-line
  }, [userState.isLoading]);

  // Until we have loaded the user and goals we need to wait
  if (userState.isLoading || userState.goals === null)
    return <LoadingIndicator />;

  function handleNavigateToEditGoal(goalId) {
    navigate("/editGoal?id=" + goalId);
  }

  async function handleGoalUpdate(goalId, dateString, iconKey) {
    await setGoalDayValue(goalId, dateString, iconKey)
  }

  const monthsAndCounts = GetMonthsAndCounts(dateList);
  let monthHeaders = monthsAndCounts.map((monthObj) => {
    return (<th key={monthObj.monthString} colSpan={monthObj.count} className='monthHeader'>{monthObj.monthString}</th>);
  });

  let dateHeaders = dateList.map((dateString) => {
    var displayDate = GetDateStringDisplayObject(dateString);
    return (
      <th key={dateString} className={dateString === highlightDateString ? "highlightedCell" : ""}>
        {displayDate.weekDay}<br />{displayDate.day}<sup>{displayDate.daySuffix}</sup>
      </th>
    )
  });

  let rows = userState.goals.map((goal) => (
    <tr key={goal.id}>

      <th scope="row">{goal.title} <Button size="sm" variant="outline-primary" onClick={() => handleNavigateToEditGoal(goal.id)}>✏️</Button></th>

      {
        dateList.map((dateString) => (
          <td key={dateString} className={dateString === highlightDateString ? "highlightedCell" : ""}>
            {(<DotButton
              isDisabled={dateString > disableGreaterThanDateString}
              isHighlighted={dateString === highlightDateString}
              goalDataIcons={goal.goalDataIcons}
              dotState={goal.goalLogs.get(dateString)}
              onDotButtonClicked={(iconKey) => handleGoalUpdate(goal.id, dateString, iconKey)} />)}
          </td>
        ))}
    </tr>));

  // Only show the "add Goal" button if we have < 10 Goals already
  let addGoalRow =
    <tr>
      <td>
        {
          (userState.goals.length < 10) && <Button variant="outline-info" onClick={() => navigate("/createGoal")} >➕</Button>
        }
      </td>
      <td colSpan={dateHeaders.length}>
        <div className='dateNav'>
          <Button variant="outline-light" onClick={onDateBackClicked}>{"⬅️"} Back</Button>
          {' '}
          <Button variant="outline-light" onClick={onDateForwardClicked}>Forward {"➡️"}</Button>
        </div>
      </td>
    </tr>;

  return (
    <Table variant='dark' >
      <thead>
        <tr key="monthHeaders">
          <td key="blank" className='blank' />
          {monthHeaders}
        </tr>
        <tr key="dateHeaders">
          <td key="blank" />
          {dateHeaders}
        </tr>
      </thead>
      <tbody>
        {rows}
        {addGoalRow}
      </tbody>
    </Table>
  );
};