import './App.css';
import DotGoalApp from "./DotGoalApp";
import SiteHeader from './Structure/SiteHeader';
import SiteFooter from './Structure/SiteFooter';
import CreateUserView from './DotGoal/CreateUserView';
import EditGoalView from './DotGoal/EditGoalView';
import WeekView from './DotGoal/WeekView.js';
import TrackingView from './DotGoal/TrackingView.js';
import Container from 'react-bootstrap/Container';
import {
  Routes,
  Route
} from "react-router-dom";
import ErrorView from './Structure/ErrorView';
import { ErrorBoundary } from './Structure/ErrorBoundary';
import { UserProvider } from './Managers/UserContext';
import AboutView from './Structure/AboutView.js';

function App() {
  return (
    <>
      <ErrorBoundary>
        <UserProvider>
          <SiteHeader />
          <Container className="App">
            <Routes>
              <Route path="/week" element={
                <WeekView />} />
              <Route path="/rollingweek" element={
                <TrackingView viewSize={7} />} />
              <Route path="/tracking" element={
                <TrackingView viewSize={3} />} />

              <Route path="/createUser" element={
                <CreateUserView />} />
              <Route path="/createGoal" element={
                <EditGoalView />} />
              <Route path="/editGoal" element={
                <EditGoalView />} />

              <Route path="/error" element={
                <ErrorView />} />

              <Route path="/about" element={
                <AboutView />} />

              <Route path="/" element={
                <DotGoalApp />} />
            </Routes>
          </Container>
          <SiteFooter />
        </UserProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;