import React from 'react';
import { Button } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import ExampleDayTrackingImg from '../Assets/Screenshots/ExampleDayTracking.png'
import ExampleRollingWeekImg from '../Assets/Screenshots/ExampleRollingWeek.png'
import ExampleGoalIconsSimpleImg from '../Assets/Screenshots/ExampleGoalIconsSimple.png'
import ExampleGoalIconsMediumImg from '../Assets/Screenshots/ExampleGoalIconsMedium.png'
import ExampleGoalIconsComplexImg from '../Assets/Screenshots/ExampleGoalIconsComplex.png'

export default function HomeView() {
  return (
    <>
      <div className="bg-dark p-5 rounded-lg m-3">
        <h1 className="display-4">DotGoal App [DEV]</h1>
        <p className="lead">Create your goals, track them daily, with simple icons 😀</p>
        <hr />
        <p>Already have an account or ready to get started?<br />Login with your Microsoft Account:</p>
        <Button variant="primary" size="lg" href="/.auth/login/aad">Let's Go ➡️</Button>
      </div>

      <div className="container marketing">

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Track your Goals Daily</h2>
            <p className="lead">DotGoal lets you set daily targets and mark your progress using a range of expressive emojis. Whether it's fitness, hobbies, or wellness, make each day count towards your personal success.</p>
          </div>
          <div className="col-md-5">
            <Image src={ExampleDayTrackingImg} fluid />
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7 order-md-2">
            <h2 className="featurette-heading">Review your Week</h2>
            <p className="lead">Look back at your week, reviewing your goals. Take stock and move forward.</p>
          </div>
          <div className="col-md-5 order-md-1">
            <Image src={ExampleRollingWeekImg} fluid />
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-7">
            <h2 className="featurette-heading">Customize each Goal</h2>
            <p className="lead">Define a title and up to 10 icons to represent your impact on that goal. Track a variety of tasks, or simple success/failure results.</p>
          </div>
          <div className="col-md-5">
            <Carousel>
              <Carousel.Item>
                <Image src={ExampleGoalIconsSimpleImg} fluid />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={ExampleGoalIconsMediumImg} fluid />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={ExampleGoalIconsComplexImg} fluid />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <hr className="featurette-divider" />

      </div>
    </>
  );
}