import { GetActiveUser } from '../Auth/AuthProvider';

// This UserManager acts as a singleton and handles calls to get User data as well as check logged instate, etc.
class UserManager {
  constructor() {
    console.log("CONSTRUCTOR!");
    this.authUser = null;
    this.authUserRequest = null;
  }

  async GetAuthUser() {
    if (this.authUserRequest == null) {
      this.authUserRequest = this.#doGetAuthUser();
    }
    return this.authUserRequest;
  }

  async IsLoggedIn() {
    let localAuthUser = await this.GetAuthUser();
    return localAuthUser != null;
  }

  async GetCurrentUser() {

  }

  async GetCurrentUserGoals() {

  }

  //
  // Private functions for actually doing the API calls to limit duplicate calls
  //

  async #doGetAuthUser() {
    if (this.authUser == null) {
      this.authUser = await GetActiveUser();
    }
    return this.authUser;
  }

}

const userManagerInstance = new UserManager();
export default userManagerInstance;

