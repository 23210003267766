import { GenerateDateStrings } from './DateProvider.js'

export default function GenerateSampleData(startDate, endDate, numGoals, today) {
  let dateStrings = GenerateDateStrings(startDate, endDate);
  let result = [];
  for (let i = 0; i < numGoals; i++) {
    result.push({
      goalName: "Goal " + (i + 1),
      goalData: GenerateSampleGoalData(dateStrings, today)
    });
  }

  console.log(result);
  return result;
}

function GenerateSampleGoalData(dateStrings, today) {
  let result = new Map();
  dateStrings.forEach(dateString => {
    let value = randomState();
    if (dateString <= today && value !== undefined)
      result.set(dateString, value);
  });
  return result;
}

const possibleStates = [true, false, undefined];
function randomState() {
  return possibleStates[Math.floor(Math.random() * 3)];
}

export const ResultType = {
  SUCCESS: Symbol('success'),
  NOT_FOUND: Symbol('not found'),
  OTHER_ERROR: Symbol('other error')
};

export async function PerformFetch(fetchUri, httpMethod, body) {
  console.log(`calling: ${httpMethod} ${fetchUri}`);
  try {
    const request = {
      method: httpMethod
    };
    if (body) {
      request.headers = { "Content-Type": "application/json" };
      request.body = JSON.stringify(body);
    }
    const response = await fetch(fetchUri, request);
    console.log(response.status + " - " + response.statusText);

    if (response.ok) {
      const responseJson = response.status !== 204 ? await (response.json()) : null;
      return {
        resultType: ResultType.SUCCESS,
        result: responseJson
      };
    } else {
      // handle HTTP errors
      return {
        resultType: response.status === 404 ? ResultType.NOT_FOUND : ResultType.OTHER_ERROR,
        errorDescription: response.status + " - " + response.statusText + " - " + await response.text()
      };
    }
  }
  catch (error) {
    // handle network errors
    console.log('Network error: ' + error);
    // Append some details of the call to the error
    error.message = `Error calling ${httpMethod} ${fetchUri} with ${body} - ` + error.message;
    throw error;
  }
}

export async function GetUser() {
  return PerformFetch("/api/user", "GET");
}

export async function CreateUser(displayName) {
  return PerformFetch("/api/user", "POST", { displayName: displayName });
}

// Given a Goal from the API reformat it as needed
function correctGoalFromAPIFormat(goal) {
  // Convert the goalLogs to a Map for easy access
  goal.goalLogs = new Map(Object.entries(goal.goalLogs));

  if (goal.goalDataIcons) {
    // Convert the goalDataIcons to a Map for easy access
    goal.goalDataIcons = new Map(Object.entries(goal.goalDataIcons));
  }
  else {
    // Add in 'goalDataIcons' if loading a legacy document
    goal.goalDataIcons = new Map([
      ["0", "🟢"],
      ["1", "🔴"]]);
  }
}

export async function GetGoals() {
  let response = await PerformFetch("/api/goal", "GET");
  if (response.resultType === ResultType.SUCCESS) {
    for (const goal of response.result) {
      correctGoalFromAPIFormat(goal);
    }
  }

  return response;
}

export async function CreateGoal(title, goalDataIcons) {
  const response = await PerformFetch("/api/goal",
    "POST",
    {
      title: title,
      goalDataIcons: Object.fromEntries(goalDataIcons) // Convert the goalLogs from a Map to just an Obj
    });
  if (response.resultType === ResultType.SUCCESS) {
    correctGoalFromAPIFormat(response.result);
  }
  return response;
}

export async function PatchGoal(goalId, { goalLogs, title, goalDataIcons }) {
  const body = {};
  if (goalLogs) {
    body.goalLogs = Object.fromEntries(goalLogs); // Convert the goalLogs from a Map to just an Obj

    // Patch up any legacy true/false goalLogs
    const entries = Object.entries(body.goalLogs);
    for (const [key, value] of entries) {
      if (typeof value === 'boolean')
        body.goalLogs[key] = value ? "0" : "1";
    }
  }

  if (title)
    body.title = title;

  if (goalDataIcons) {
    body.goalDataIcons = Object.fromEntries(goalDataIcons); // Convert the goalLogs from a Map to just an Obj
  }

  const response = await PerformFetch(`/api/goal?goalId=${goalId}`, "PATCH", body);
  if (response.resultType === ResultType.SUCCESS) {
    correctGoalFromAPIFormat(response.result);
  }
  return response;
}

export async function DeleteGoal(goalId) {
  return PerformFetch(`/api/goal?goalId=${goalId}`, "DELETE");
}