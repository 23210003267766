import './ErrorView.css';
import { Link } from "react-router-dom";
import { useContext } from 'react';
import { ErrorContext } from './ErrorBoundary';

export default function ErrorView() {
  // TODO: Remove details here for production builds
  const { error } = useContext(ErrorContext);
  console.log(error);

  return (
    <>
      Sorry there was an Error! - <Link to="/">Go Back Home</Link>
      <hr />
      <b>Error Name:</b> {error?.name}
      <b>Error Message:</b> {error?.message}
      <b>Callstack:</b> <div className="callstack"> {error?.stack} </div>
      <b>Full Error:</b> {error instanceof Object ? JSON.stringify(error) : error}
    </>
  );
}