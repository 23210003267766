import React from 'react'

export default function SiteFooter() {
  // TODO: Would it work to replace this with a Navbar and set 'fixed-bottom' to avoid needing the hacks in index.html?
  return (
    <footer className="footer mt-auto py-1 bg-light">
      <div className="container footer-copyright text-center py-1">
        © 2024 Copyright: Roy Herrod (<a href="https://roybott.xyz" style={{ color: "black" }}>Roybott.xyz</a>)
      </div>
    </footer>
  );
}