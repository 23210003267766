export function GenerateDateStrings(startDate, endDate) {
  if (!(startDate instanceof Date))
    throw new Error('startDate - Expected a Date object, but got ' + typeof date);
  if (!(endDate instanceof Date))
    throw new Error('endDate - Expected a Date object, but got ' + typeof date);

  let result = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    result.push(FormatAsDateString(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return result;
}

// Given a date, generate a list of DateStrings representing the full week, starting Monday
export function GenerateWeekDateStrings(givenDate) {
  // Clone the current date to avoid mutating the original object
  const date = new Date(givenDate);

  // Find the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const dayOfWeek = date.getDay();

  // Calculate the difference in days to Monday (Monday is 1 day after Sunday)
  const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

  // Calculate the date for Monday of the current week
  date.setDate(date.getDate() - daysUntilMonday);

  // Create an array to store the dates for Monday to Sunday
  const weekDates = [];

  // Generate dates for Monday to Sunday and store them in the array
  for (let i = 0; i < 7; i++) {
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + i);
    weekDates.push(FormatAsDateString(newDate));
  }

  return weekDates;
}

// Return date as string in format 'YYYY-MM-DD'
export function FormatAsDateString(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

const ordinalSuffix = ["th", "st", "nd", "rd"];
const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export function GetDateStringDisplayObject(dateString) {
  const date = new Date(dateString);
  const day = date.getUTCDate().toString().padStart(2, '0');
  const weekday = weekdays[date.getUTCDay()];
  const daySuffix = ordinalSuffix[(day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10];
  return { weekDay: weekday, day: day, daySuffix: daySuffix };
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
// Expects dates in "YYYY-MM-DD" DateString format
export function GetMonthsAndCounts(dateStringArray) {
  // Object to keep track of month counts
  const monthCounts = {};

  dateStringArray.forEach(dateString => {
    const [year, month] = dateString.split('-').map(Number);
    const yearMonthKey = `${year}-${month}`;

    if (!monthCounts[yearMonthKey]) {
      monthCounts[yearMonthKey] = 1;
    } else {
      monthCounts[yearMonthKey]++;
    }
  });

  // Map the counts to the desired output format
  return Object.keys(monthCounts).map(key => {
    const [year, month] = key.split('-').map(Number);
    return {
      monthString: `${monthNames[month - 1]} ${year}`,
      count: monthCounts[key]
    };
  });
}