import React, { createContext, useState } from 'react';
import { useNavigate } from "react-router-dom";

// Create an ErrorContext using createContext
const ErrorContext = createContext();

// ErrorBoundary component
const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Error handler function
  const redirectToError = (error) => {
    console.log("RedirectToError");
    console.log(error);
    setError(error);
    // Perform any additional error handling logic here
    // For example, trigger a redirect or render an error page
    navigate("/error");
  };

  return (
    <ErrorContext.Provider value={{ redirectToError, error }}>
      {children}
    </ErrorContext.Provider>
  );
};

// Export the ErrorContext and ErrorBoundary
export { ErrorContext, ErrorBoundary };