import { useState } from 'react';
import CalendarView from "./CalendarView.js"
import { FormatAsDateString, GenerateWeekDateStrings } from "./Helpers/DateProvider.js"

export default function WeekView() {
  let today = new Date();
  const dates = GenerateWeekDateStrings(today);

  // Create a 'today' from the date string to avoid timezones
  let todayDateString = FormatAsDateString(new Date());

  const [dateList, setDateList] = useState(dates);

  function handleDateBackClicked() {
    let newCurrentDate = new Date(dateList[0]);
    newCurrentDate.setDate(newCurrentDate.getDate() - 1);

    setDateList(GenerateWeekDateStrings(newCurrentDate));
  }

  function handleDateForwardClicked() {
    let newCurrentDate = new Date(dateList[dateList.length - 1]);
    newCurrentDate.setDate(newCurrentDate.getDate() + 2);

    setDateList(GenerateWeekDateStrings(newCurrentDate));
  }

  return (
    <>
      <i>Here you can see your goals and track progress each day, as well as navigate back to see past data.</i>
      <CalendarView
        dateList={dateList}
        disableGreaterThanDateString={todayDateString}
        highlightDateString={todayDateString}
        onDateBackClicked={handleDateBackClicked}
        onDateForwardClicked={handleDateForwardClicked} />
    </>
  );
};