import { useState, useEffect } from 'react';
import CalendarView from "./CalendarView.js"
import { FormatAsDateString, GenerateDateStrings } from "./Helpers/DateProvider.js"

export default function TrackingWeekView({ viewSize }) {
  const [dateList, setDateList] = useState([]);

  useEffect(() => {

    let endDate = new Date();
    endDate.setDate(endDate.getDate());
    let startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - (viewSize - 1));

    const dates = GenerateDateStrings(startDate, endDate);
    setDateList(dates);
  }, [viewSize]);

  // Create a 'today' from the date string to avoid timezones
  let todayDateString = FormatAsDateString(new Date());

  function handleDateBackClicked() {
    let newEndDate = new Date(dateList[0]);
    newEndDate.setDate(newEndDate.getDate());

    let newStartDate = new Date(newEndDate);
    newStartDate.setDate(newStartDate.getDate() - (viewSize - 1));

    setDateList(GenerateDateStrings(newStartDate, newEndDate));
  }

  function handleDateForwardClicked() {
    let newStartDate = new Date(dateList[dateList.length - 1]);
    newStartDate.setDate(newStartDate.getDate() + 2);

    let newEndDate = new Date(newStartDate);
    newEndDate.setDate(newEndDate.getDate() + (viewSize - 1));

    setDateList(GenerateDateStrings(newStartDate, newEndDate));
  }

  return (
    <>
      <i>Here you can see your goals and track progress each day, as well as navigate back to see past data.</i>
      <CalendarView
        dateList={dateList}
        disableGreaterThanDateString={todayDateString}
        highlightDateString={todayDateString}
        onDateBackClicked={handleDateBackClicked}
        onDateForwardClicked={handleDateForwardClicked} />
    </>
  );
};