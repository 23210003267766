import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../Managers/UserContext';

export default function CreateUserView() {
  const { addUser } = useContext(UserContext);

  const navigate = useNavigate();

  const [displayName, setDisplayName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const errorMsg = "Display Name must be at least 1 character long";

  async function handleSubmit(e) {
    e.preventDefault();
    if (displayName.length < 1) {
      setErrorMessage(errorMsg);
    } else {
      setErrorMessage('');
      await addUser(displayName);
      console.log("CreateUserView navigating to /");
      navigate("/");
    }
  }

  function handleDisplayNameChange(e) {
    setDisplayName(e.target.value);
    setErrorMessage(e.target.value.length < 1 ? errorMsg : '');
  }

  return (
    <>
      Create User Page!
      <hr />
      <i>It looks like it's your first time here, please share a little about yourself:</i>
      <hr />
      <form onSubmit={handleSubmit}>
        <label>
          Enter Display Name: { }
          <input name="displayName"
            type="text"
            maxLength="15"
            minLength="1"
            onChange={handleDisplayNameChange}
            value={displayName}
            aria-describedby="displayName-error" />
          {errorMessage && <div id="displayName-error" className="formErrorText" role="alert">{errorMessage}</div>}
        </label>
        <br />
        <button type="submit">GO</button>
      </form>
    </>
  );
}