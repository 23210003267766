import './DotButton.css'
import Button from 'react-bootstrap/Button';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import { useState, useRef } from 'react';

export default function DotButton({ isDisabled, isHighlighted, goalDataIcons, dotState, onDotButtonClicked }) {
  const [processingRequest, setProcessingRequest] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const target = useRef(null);

  const handleOnClick = async (iconKey) => {
    setProcessingRequest(true);
    setShowOptions(false);
    await onDotButtonClicked(iconKey);
    setProcessingRequest(false);
  }

  var popoverBody = Array.from(goalDataIcons.keys()).map(iconKey => (
    <Button
      key={iconKey}
      variant="outline-light"
      onClick={() => handleOnClick(iconKey)}
      className="dotButtonOption"
    >
      {goalDataIcons.get(iconKey)}
    </Button>
  ));

  const numIcons = goalDataIcons.size;
  const popoverClass = numIcons === 6 ? "popover6" : numIcons === 7 || numIcons === 8 ? "popover7or8" : "popover0to5or9to10";

  return (
    <div ref={target}>
      <Button
        variant="outline-light"
        disabled={isDisabled || processingRequest}
        onClick={() => setShowOptions(!showOptions)}
        className={isHighlighted ? "highlightedDotButton" : "dotButton"}
      >
        {dotState !== undefined ? goalDataIcons.get(dotState) ?? "?" : "_"}
      </Button>
      <Overlay
        show={showOptions}
        target={target}
        placement="top"
        container={target}
      >
        <Popover id="popover-contained" className={popoverClass}>
          <Popover.Body>
            {popoverBody}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};
